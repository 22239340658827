import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base";
import { StyledTabsList, StyledTab, StyledTabSelected } from "./tabs"
import { regular14, regular16 } from "./typography";

const SectionEvents = styled.section`
  ${baseGridStyles}
  padding-top: 0;
  background: ${props => props.background ? props.background : 'transparent'};
  @media screen and (min-width: ${breakpoints.l}px) {
    max-width: ${props => props.background ? '100%' : 'auto'};
  }
  
`

export { 
  SectionEvents
}