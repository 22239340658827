import React from "react"
import {
    ReactVideoPlayer,
    ReactAudioPlayer
} from "../components/reactPlayer"

const fixture = [
    {
        quote: `<p>I attended Karina's 'ENERGY BOOST' mini class and I was totally blown away the next morning, seeing the way I was moving when I went for my morning walk.</p><p>The class had done justice to its title 'Energy Boost' in its truest sense..... I was walking as if I was a young girl (I am 64 years young right now).</p><p> Not only this, I was also amazed by the way Karina taught during the class. The net result of these experiences was that I immediately signed up for Karina's 'Quantum-Touch Level-1 Program'. I was even more impressed by Karina's energy and way of teaching, and went ahead to sign up for her "Quantum-Touch Supercharging Program'.</p>

        <p>I am indeed grateful to Karina for the magical experiences I had during these programs and thereafter. These are programs, which no one should miss.</p>`,
        citationName: `Dr Shabnam Johry`
    }
]
export default fixture